.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-layout {
  height: 100%;
  min-height: 100vh;
}

aside.ant-layout-sider.ant-layout-sider-dark {
  flex: 0 0 260px !important;
  max-width: 260px !important;
  min-width: 260px !important;
  width: 260px !important;
  background: #000000 !important;
}

.coin_stat_card {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 50px 0px;
}

.coin_stat_card .card_icon {
  font-size: 40px;
  position: absolute;
  bottom: 80px;
  left: 0px;
  right: 0px;
}

.coin_stat_card .amount {
  font-size: 24px;
  font-weight: 500;
}

.stat_card {
  height: fit-content;
  width: 24%;
  text-align: center;
}

.stat_card .card_heading1 {
  font-size: 18px;
  font-weight: 700;

}

.stat_card .card_heading2 {
  font-size: 18px;
  font-weight: 500;
  

}

.stat_card .card_icon {
  /* display: flex; */
  align-items: center;
  justify-content: space-around;
  width: 100%;
  font-size: 60px;
}

.breadcrumb_item {
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 10px;
}

.breadcrumb_item:hover {
  background: rgb(215, 211, 211);
  scale: 1.02;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.phn_cs input {
  height: 55px;
}

h4 {
  color: #011529;
}

h5 {
  font-size: 14px;
  font-weight: 400;
  color: grey;
}

.ant-select-selector {
  height: 55px !important;
  display: flex;
  align-items: center;
}

.ant-select-arrow {
  top: 40% !important;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: #f7f4f4 !important;
  box-shadow: none !important;
}

.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0px 20px 20px 20px !important;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
  background-color: white;
  width: 400px !important;
  max-height: 220px !important;
  overflow-y: scroll;
  border-radius: 0px !important;
  height: 600px !important;
  max-height: 250px !important;

  @media (max-width: 1280px) {
    width: 300px !important;
  }

  @media (max-width: 992px) {
    width: 230px !important;
  }
}

.react-tel-input .form-control {
  font-size: 16px;
  /* background: #f7f4f4 !important; */
  border: 1px solid black;
  /* border-radius: 5px; */
  width: 100% !important;
  outline: none;
  padding: 18.5px 14px 18.5px 60px;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  /* color: #495057; */
  /* border: none !important; */
  border-radius: 10px !important;
  margin-bottom: 10px !important;
  display: flex !important;
  height: 55px !important;
  /* &:focus {
    border-color: "red" !important;
    box-shadow: none !important;
  } */
}

.react-tel-input .country-list .search {
  z-index: 2;
  position: sticky;
  top: 0;
  background-color: red;
  padding: 10px 0 6px 10px;
  width: 100%;
}

.react-tel-input .country-list .search-box {
  /* border: 1px solid $primaryClr !important; */
  border-radius: 10px;
  font-size: 15px;
  line-height: 15px;
  margin-left: 6px;
  padding: 7px 11px 9px !important;
  outline: none;
  width: 94%;
  box-sizing: border-box;
  /* &::placeholder {
    text-transform: capitalize;
  } */
}

.srch {
  display: grid;
  gap: 25px;
}

.table_icon svg {
  margin-top: 8px;
  width: 60px;
}

span.coin_name {
  margin-left: 8px;
  color: grey;
  font-weight: 500;
}

span.coin_nme {
  padding-left: 20px;
  align-self: center;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: grey; /* Change the border color of the checked checkbox */
}

.ant-layout-sider-children {
  overflow-y: scroll;
  /* padding-left: 20px; */
  width: 260px ;

}
::-webkit-scrollbar {
  width: 3px;
}


.custm_btn{
  background-color: #FFFFFF !important; 

}


.resendBtn:hover{
  background-color: #1e90ff !important;
  color: #FFFFFF !important;
}

.meta_btn{
background-color: #ffffff ;
/* border: 1px solid #c7c4c4;
border-radius: 6px;
padding: 10; */
border: none;
cursor: pointer;

}

.customTable .ant-pagination-options {
  display: none;
}

.userTable .ant-pagination-options {
  display: none;
}
