#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.logo_img {
    height: 100px;
    margin: auto;
}

.logo_img_small {
    height: 50px;
    margin: auto;
    margin-top: 10px;
}

.logo {
    display: flex;
}

.auth_wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    background: linear-gradient(45deg, #000000, #000000, #000000);
}

.ant-menu-dark{
    color: rgba(255, 255, 255, 0.65);
    background: black !important;
}

span.ant-menu-title-content {
    font-size: 14px;
}


.side_menu svg {
    font-size: 20px;
}

.side_menu .ant-menu-item-active {
    background-color: #1e90ff !important;
}

.ant-menu-dark .ant-menu-item-selected{
    background-color: #1e90ff  !important;
   
    font-weight: 500;
    
}