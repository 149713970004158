.login_card {
    border: 1px solid #f0f0f0;
    width: 'fit-content';
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_img {
    display: flex;
    margin: 20px 0px;
}

.login_img img {
    height: 140px;
    width: 140px;
    margin: auto;
}